import React from 'react';
import { Helmet } from 'react-helmet';

import PageLayout from '../layouts/Page';

export default function Template() {
  return (
    <PageLayout>
      <Helmet>
        <title>Conditions générales d'utilisation</title>
      </Helmet>
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
              <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Conditions générales d'utilisation
              </h1>
              <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">
                <p>
                  Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet
                  l'encadrement juridique des modalités de mise à disposition du site et des
                  services par THEBRIGADE FRANCE et de définir les conditions d'accès et
                  d'utilisation du site app.thank-you.io par « l'Utilisateur ».
                  <br />
                  <br />
                  Toute inscription ou utilisation du site implique l'acceptation sans aucune
                  réserve ni restriction des présentes CGU par l'utilisateur. Lors de l'inscription
                  sur le site via le Formulaire d'inscription, chaque utilisateur accepte
                  expressément les présentes CGU en cochant la case précédant le texte suivant : «
                  Je reconnais avoir lu et compris les CGU et je les accepte ».
                  <br />
                  En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur
                  se doit de renoncer à l'accès des services proposés par le site.
                  <br />
                  App.thank-you.io se réserve le droit de modifier unilatéralement et à tout moment
                  le contenu des présentes CGU.
                </p>

                <h2>Article 1 : Les mentions légales</h2>
                <p>
                  L'édition du site app.thank-you.io est assurée par la Société SAS THEBRIGADE
                  FRANCE au capital de 1000 euros, immatriculée au RCS de LILLE sous le numéro
                  883705667, dont le siège social est situé au 38 BD CARNOT Le Directeur de la
                  publication est : Ousaci Damien.
                  <br />
                  <br />
                  L'hébergeur du site app.thank-you.io est la société AMAZON WEB SERVICES EMEA SARL,
                  dont le siège social est situé au 5 rue Plaetis - L 233 Luxembourg.
                </p>
                <h2>Article 2 : Accès au site</h2>
                <p>
                  Le site app.thank-you.io permet à l'Utilisateur un accès aux services suivants :
                  <br />
                  un logiciel de traitement de données numériques d'acquisition marketing qui aide
                  les directions marketing & acquisition à analyser le parcours de conversion du
                  levier affiliation de chaque client avant une transaction afin d'en comprendre son
                  comportement face à des publicités pour prendre des décisions mesurées sur leurs
                  investissements publicitaires en affiliation.
                  <br />
                  Le site est accessible en tout lieu à tout Utilisateur ayant un accès à Internet.
                  Tous les frais supportés par l'Utilisateur pour accéder au service (matériel
                  informatique, logiciels, connexion Internet, etc.) sont à sa charge.
                  <br />
                  <br />
                  Pour accéder aux services, l'Utilisateur doit s'identifier à l'aide de son
                  identifiant et de son mot de passe qui lui seront communiqués par mail.
                  <br />
                  Tout Utilisateur inscrit pourra également solliciter sa désinscription en se
                  rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un
                  délai raisonnable.
                  <br />
                  L'Utilisateur a la possibilité de contacter le site par messagerie électronique à
                  l'adresse email de l'éditeur communiqué à l'ARTICLE 1.
                </p>
                <h2>Article 3 : Collecte des données</h2>
                <p>
                  Le site assure à l'Utilisateur une collecte et un traitement d'informations
                  personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6
                  janvier 1978 relative à l'informatique, aux fichiers et aux libertés. En vertu de
                  la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose
                  d'un droit d'accès, de rectification, de suppression et d'opposition de ses
                  données personnelles. L'Utilisateur exerce ce droit :
                  <br />· par mail à l'adresse email dpo@thank-you.io
                  <br />· via un formulaire de contact ;
                </p>
                <h2>Article 4 : Propriété intellectuelle</h2>
                <p>
                  Les marques, logos, signes ainsi que tous les contenus du site (textes, images,
                  son…) font l'objet d'une protection par le Code de la propriété intellectuelle et
                  plus particulièrement par le droit d'auteur.
                  <br />
                  <br />
                  L'Utilisateur doit solliciter l'autorisation préalable du site pour toute
                  reproduction, publication, copie des différents contenus. Il s'engage à une
                  utilisation des contenus du site dans un cadre strictement privé, toute
                  utilisation à des fins commerciales et publicitaires est strictement interdite.
                  <br />
                  Toute représentation totale ou partielle de ce site par quelque procédé que ce
                  soit, sans l'autorisation expresse de l'exploitant du site Internet constituerait
                  une contrefaçon sanctionnée par l'article L 335-2 et suivants du Code de la
                  propriété intellectuelle.
                  <br />
                  Il est rappelé conformément à l'article L122-5 du Code de propriété intellectuelle
                  que l'Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer
                  l'auteur et sa source.
                </p>
                <h2>Article 5 : Responsabilité</h2>
                <p>
                  Le site app.thank-you.io ne peut être tenue responsable de l'utilisation et de
                  l'interprétation de l'information contenue dans ce site.
                  <br />
                  L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot
                  de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à
                  l'utilisation de son identifiant et mot de passe. Le site décline toute
                  responsabilité.
                </p>
                <h2>Article 6 : Liens hypertextes</h2>
                <p>
                  Des liens hypertextes peuvent être présents sur le site. L'Utilisateur est informé
                  qu'en cliquant sur ces liens, il sortira du site app.thank-you.io. Ce dernier n'a
                  pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne
                  saurait, en aucun cas, être responsable de leur contenu.
                </p>
                <h2>Article 7 : Cookies</h2>
                <p>
                  L'Utilisateur est informé que lors de ses visites sur le site, un cookie peut
                  s'installer automatiquement sur son logiciel de navigation. Les cookies sont de
                  petits fichiers stockés temporairement sur le disque dur de l'ordinateur de
                  l'Utilisateur par votre navigateur et qui sont nécessaires à l'utilisation du site
                  app.thank-you.io.
                  <br />
                  Les cookies ne contiennent pas d'information personnelle et ne peuvent pas être
                  utilisés pour identifier quelqu'un. Un cookie contient un identifiant unique,
                  généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la
                  visite de l'Utilisateur, d'autres restent. En naviguant sur le site, L'Utilisateur
                  les accepte.
                </p>
                <h2>ARTICLE 8 : Droit applicable et juridiction compétente</h2>
                <p>
                  La législation française s'applique au présent contrat. En cas d'absence de
                  résolution amiable d'un litige né entre les parties, les tribunaux français seront
                  seuls compétents pour en connaître.
                  <br />
                  Pour toute question relative à l'application des présentes CGU, vous pouvez
                  joindre l'éditeur aux coordonnées inscrites à l'ARTICLE 1.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
